import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import ListenerIntergrations from '../components/general/single-listener-cpt/ListenerIntergrations'
import BreadcrumbSection from '../components/breadcrumb'
import { getSlug, replaceStr } from '../helper'
import { LISTENER_SHORTCODE } from '../constants'
import PopupSchedule from '../components/popupSchedule/PopupSchedule';

const CptListenerTrackingIntegrationTemplate = ({ data, location }) => {
    const { tabs, mainElements, relatedAlternative, } = data.wpCptListener.cfListener
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = data.wpCptListener.cfListener.tabs.trackingintegrationsSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = data.wpCptListener.cfListener.tabs.trackingintegrationsDefaultSeoFields
    const listenerSlug = getSlug(data.wpCptListener.uri)
    const listernerCptSlug = `${listenerSlug}/${mainElements.slug}`
    const breadcrumbItems = [
        {
            name: `Listeners`,
            url: listenerSlug,
        },
        {
            name: mainElements.breadcrumbValue,
            url: listernerCptSlug,
        },
        {
            name: `Integrations`,
        }
    ]

    const listenerName = [mainElements.listenerNamePlural, mainElements.listenerNameSingular]
    const listenerType = data.wpCptListener.taxListenerTypes.nodes.length > 0 && data.wpCptListener.taxListenerTypes.nodes[0].name ? data.wpCptListener.taxListenerTypes.nodes[0].name : '';
    const listenerFilterType = data.wpCptListener.taxListenerFilterTypes.nodes.length > 0 && data.wpCptListener.taxListenerFilterTypes.nodes[0].name ? data.wpCptListener.taxListenerFilterTypes.nodes[0].name : ''

    if (mainElements.hideTabs && mainElements.hideTabs.includes('Tracking Integrations')) {
        if (typeof window !== "undefined") {
            window.location.href = `/${listernerCptSlug}`
        }
    }

    const canonical = process.env.GATSBY_SITE_URL + location.pathname


    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={replaceStr(
                    LISTENER_SHORTCODE,
                    listenerName,
                    titleTag
                )}
                description={replaceStr(
                    LISTENER_SHORTCODE,
                    listenerName,
                    metaDescription
                )}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="listener-integration"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <ListenerIntergrations
                tabs={tabs}
                mainElements={mainElements}
                relatedAlternative={relatedAlternative}
                wpCptListener={data.wpCptListener}
                listenerName={listenerName}
                allWpCptPlatform={data.allWpCptPlatform}
                listenerType={listenerType}
                listenerFilterType={listenerFilterType}
            />
        </Layout>
    )
}

export default CptListenerTrackingIntegrationTemplate;

export const pageQuery = graphql`
    query PageTrackingBySlug($slugTab: String!, $slugListener: String!) {
        wpCptListener(cfListener: {mainElements: {slug: {eq: $slugListener}}, tabs: {trackingintegrationsSlug: {eq: $slugTab}}}){
            cfListener {
                mainElements {
                    breadcrumbValue
                    comingSoonMessage
                    nameOfEmailList
                    hideTabs
                    listenerExcerpt
                    listenerNamePlural
                    listenerNameSingular
                    logoFileSvg
                    nameOfEmailList
                    slug
                    status
                    useImageInsteadOfSvg
                    descriptionAboveSearch
                    instructionsAboveSearch
                    createAccountLiveStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    createAccountComingSoonStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    logoFileImage {
                        ...WordpressImageFields
                    }
                    iconFileSvg
                    iconUseImageInsteadOfSvg
                    iconFileImage {
                        ...WordpressImageFields
                    }
                    overrideTypesRulesCombination
                }
                relatedAlternative {
                    relatedDescription
                    relatedH3
                    showRelated
                    showAlternatives
                    overrideAlternativeDescription
                    customAlternativeDescription
                    alternativeListeners {
                        ...WordpressCPTListenerRelatedFields
                    }
                    relatedListeners {
                        ...WordpressCPTListenerRelatedFields
                    }
                }
                tabs {
                    helpSlug
                    usecasesSlug
                    datalayersamplesSlug
                    trackingintegrationsSlug
                    trackingintegrationsH3
                    trackingintegrationsH2
                    trackingintegrationsH1
                    trackingintegrationsIntro
                    instructionsAboveSearch
                    commonSearchedPlatformHeadline
                    trackingintegrationsCommonSearchedPlatforms {
                        ...WordpressCPTPlatformRelatedFields
                    }
                    trackingintegrationsDefaultSeoFields {
                        ogDescription
                        ogTitle
                        ogType
                        twitterCard
                        ogImage {
                            ...WordpressImageFields
                        }
                    }
                    trackingintegrationsSeoFields {
                        canonicalTagOverride
                        metaDescription
                        robotsDirective
                        titleTag
                    }
                }
            }
            taxListenerTypes {
                nodes {
                name
                }
            }
            taxListenerFilterTypes {
                nodes {
                name
                }
            }
            uri
        }
        allWpCptPlatform {
            nodes {
                cfPlatform {
                    tabs {
                        rules {
                            listenerTypes {
                                name
                            }
                            listenerFilterTypes {
                                name
                            }
                            formsTagmanagerIntroduction {
                                slug
                            }
                        }
                    }
                    mainElements {
                        platformNamePlural
                        useImageInsteadOfSvg
                        logoFileSvg
                        logoFileImage {
                            ...WordpressImageFields
                        }
                        iconFileSvg
                        iconUseImageInsteadOfSvg
                        iconFileImage {
                            ...WordpressImageFields
                        }
                    }
                }
            }
        }
    }
`
