import React from 'react'
import { logoType, getSlug, replaceStr } from "../../../helper"
import IntroListenerSection from "./layout/IntroListenerSection"
import MainListenerSection from "./layout/MainListenerSection"
import RelatedListenerSection from "./layout/RelatedListenerSection"
import uniqBy from 'lodash/uniqBy'
import last from 'lodash/last'
import { LISTENER_SHORTCODE } from "../../../constants"

const ListenerIntergrations = ({ tabs, mainElements, relatedAlternative, wpCptListener, allWpCptPlatform, listenerName, listenerType, listenerFilterType }) => {
    const listenerSlug = `/${getSlug(wpCptListener.uri)}/${mainElements.slug}`
    const introHeadline = [
        {
            headlineValue: replaceStr(
                LISTENER_SHORTCODE,
                listenerName,
                tabs.trackingintegrationsH1
            ), headlineType: 'H1'
        },
        {
            headlineValue: replaceStr(
                LISTENER_SHORTCODE,
                listenerName,
                tabs.trackingintegrationsH2
            ), headlineType: 'H2'
        }
    ]

    const introBodyText = {
        headlines: [{
            headlineValue: replaceStr(
                LISTENER_SHORTCODE,
                listenerName,
                tabs.trackingintegrationsH3
            ), headlineType: 'H3'
        }],
        body: [{
            bodyCopy: replaceStr(
                LISTENER_SHORTCODE,
                listenerName,
                tabs.trackingintegrationsIntro
            )
        }]
    }

    const listenerTabs = {
        listSlug: {
            overviewSlug: listenerSlug,
            datalayersamplesSlug: `${listenerSlug}/${tabs.datalayersamplesSlug}`,
            trackingintegrationsSlug: `${listenerSlug}/${tabs.trackingintegrationsSlug}`,
            usecasesSlug: `${listenerSlug}/${tabs.usecasesSlug}`,
            helpSlug: `${listenerSlug}/${tabs.helpSlug}`,
        },
        hideTabs: mainElements.hideTabs,
        customPostType: 'listener'
    }

    const creatAccoutContent = {
        headline: mainElements.createAccountLiveStatus.createAccountHeadline ?
            mainElements.createAccountLiveStatus.createAccountHeadline :
            'Get started for free',
        subText: mainElements.createAccountLiveStatus.createAccountSubHeadline ?
            mainElements.createAccountLiveStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const creatAccoutComingSoonContent = {
        headline: mainElements.createAccountComingSoonStatus.createAccountHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountHeadline :
            'Create a Trial Account',
        subText: mainElements.createAccountComingSoonStatus.createAccountSubHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const relatedListener = {
        titleH3: relatedAlternative.relatedH3,
        description: replaceStr(
            LISTENER_SHORTCODE,
            listenerName,
            relatedAlternative.relatedDescription
        ),
        relatedListeners: relatedAlternative.relatedListeners,
        showRelated: relatedAlternative.showRelated
    }

    const alternatives = {
        titleH3: `Alternatives to ${mainElements.listenerNameSingular}`,
        description: replaceStr(
            LISTENER_SHORTCODE,
            listenerName,
            relatedAlternative.overrideAlternativeDescription ? relatedAlternative.customAlternativeDescription : relatedAlternative.relatedDescription
        ),
        relatedListeners: relatedAlternative.alternativeListeners,
        showAlternatives: relatedAlternative.showAlternatives
    }

    const primaryLogo = logoType(
        mainElements.logoFileSvg,
        mainElements.logoFileImage,
        mainElements.useImageInsteadOfSvg,
    )

    const overrideTypesRulesCombination = mainElements.overrideTypesRulesCombination;
    const listenerCombinationTypes = overrideTypesRulesCombination !== null ? listenerFilterType : listenerType;

    let platforms = uniqBy(allWpCptPlatform.nodes, 'cfPlatform.mainElements.platformNamePlural')
    let platformConbinationList = []
    let platformCommonSearchList = []


    platforms.forEach(platform => {
        let slugCombinations = []
        let platformCombination = {}
        platform.cfPlatform.tabs.rules && platform.cfPlatform.tabs.rules.forEach(rule => {
            const types = overrideTypesRulesCombination !== null ? rule.listenerFilterTypes : rule.listenerTypes;
            types && types.forEach(listenerTypeRule => {
                if (listenerTypeRule.name === listenerCombinationTypes) {
                    platformCombination.name = platform.cfPlatform.mainElements.platformNamePlural
                    platformCombination.svg = platform.cfPlatform.mainElements.iconFileSvg
                    platformCombination.image = platform.cfPlatform.mainElements.iconFileImage
                    platformCombination.useImage = platform.cfPlatform.mainElements.iconUseImageInsteadOfSvg
                    slugCombinations.push(`${listenerTabs.listSlug.trackingintegrationsSlug}/${rule.formsTagmanagerIntroduction.slug}/`)
                }
            })
        })

        if (last(slugCombinations)) {
            platformCombination.slug = last(slugCombinations)
            platformConbinationList.push(platformCombination)
        }
    })

    if (Array.isArray(tabs.trackingintegrationsCommonSearchedPlatforms) && tabs.trackingintegrationsCommonSearchedPlatforms.length > 0) {
        tabs.trackingintegrationsCommonSearchedPlatforms.forEach((platform) => {
            const platformName = platform.cfPlatform.mainElements.platformNamePlural
            let platformCommonSearch = {}
            if (Array.isArray(platformConbinationList) && platformConbinationList.length > 0) {
                platformConbinationList.forEach((platformConbination) => {
                    platformCommonSearch.name = platformName
                    if (platformConbination.name === platformName) {
                        platformCommonSearch.slug = platformConbination.slug
                    }

                })
            }
            platformCommonSearchList.push(platformCommonSearch)
        })
    }

    const searchPlatform = {
        introSearch: tabs.instructionsAboveSearch,
        commonHeadline: tabs.commonSearchedPlatformHeadline,
        commonSearch: platformCommonSearchList,
        platforms: platformConbinationList,
    }

    return (
        <>
            <IntroListenerSection
                headlines={introHeadline}
                primaryLogo={primaryLogo}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <MainListenerSection
                tabs={listenerTabs}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                introBodyText={introBodyText}
                searchPlatform={searchPlatform}
                listenerName={listenerName}
                creatAccoutComingSoonContent={creatAccoutComingSoonContent}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <RelatedListenerSection
                relatedListener={relatedListener}
                alternatives={alternatives}
                descriptionAboveSearch={mainElements.descriptionAboveSearch}
                instructionsAboveSearch={mainElements.instructionsAboveSearch}
                listeneNamerCurrent={mainElements.listenerNamePlural}
            />
        </>
    )
}

export default ListenerIntergrations
